import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home", // 通过 redirect是属性，可以实现前端路由的重定向 如果访问的是 根路径 就会跳转到指定路径
  },
  {
    path: "/home", //工作组
    name: "home",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/home/index.vue"),
  },
  {
    path: "/about", //工作组
    name: "about",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/about/index.vue"),
  },
  {
    path: "/benchmark", //性能基准
    name: "benchmark",
    component: () =>
      import(/* webpackChunkName: "benchmark" */ "../views/benchmark/index.vue"),
  },
  {
    path: "/detail", //工作组
    name: "detail",
    component: () =>
      import(/* webpackChunkName: "detail" */ "../views/detail/index.vue"),
  },
  {
    path: "/lastdetail", //工作组
    name: "lastdetail",
    component: () =>
      import(/* webpackChunkName: "detail" */ "../views/lastdetail/index.vue"),
  },
  {
    path: "/newdetail", //工作组
    name: "newdetail",
    component: () =>
      import(/* webpackChunkName: "detail" */ "../views/newdetail/index.vue"),
  },
  {
    path: "/working", //工作组
    name: "working",
    component: () =>
      import(/* webpackChunkName: "detail" */ "../views/working/index.vue"),
  },
  {
    path: "/tool", //工作组
    name: "tool",
    component: () =>
      import(/* webpackChunkName: "detail" */ "../views/tool/index.vue"),
  },
  {
    path: "/criterion", //工作组
    name: "criterion",
    component: () =>
      import(/* webpackChunkName: "detail" */ "../views/criterion/index.vue"),
  },
  {
    path: "/billboard", //工作组
    name: "billboard",
    component: () =>
      import(/* webpackChunkName: "detail" */ "../views/billboard/index.vue"),
  },
  {
    path: "/password", // 密码重置
    name: "password",
    component: () =>
        import(/* webpackChunkName: "detail" */ "../views/password/index.vue"),
  },
  {
      path: "/indexpwd", // 密码重置
      name: "indexpwd",
      component: () =>
          import(/* webpackChunkName: "detail" */ "../views/modifyinfo/indexPwd.vue"),
  },
  {
    path: "/modifyinfo", // 密码重置
    name: "modifyinfo",
    component: () =>
        import(/* webpackChunkName: "detail" */ "../views/modifyinfo/index.vue"),
  }
]

// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location) {
//   console.log('dddd', location)
//   return originalPush.call(this, location).catch(err => err)
// }
const router = new VueRouter({
  routes,
});

export default router;
