import * as base from "./base.js";
import * as until from "./until.js";
import axios from "axios";
// // 请求方式的封装
async function get(url, data, key) {
  return new Promise((resolve, reject) => {
    // 从缓存中获取
    var record = until.cacheSync("get", key);
    // console.log('keyF', key, record)
    // 获取成功传出值
    if (key && record && record!='') {
      resolve(record);
    } else {// 获取失败发起请求
      // 判断传参方式只有get判断
      if (typeof data === "string" || typeof data === "number") {
        url = url + "/" + data;
        data = {};
      }
      // 请求头
      var header = {};
      // 配置参数
      var obj = {
        headers: header,
        method: "get",
        url: base.baseUrl + url,
        params: data,
      };
      // 发起请求
      axios(obj)
        .then((res) => {
          // 传入key说明需要存入缓存
          if (key) {
            until.cacheSync("set", key, res.data);
          }
          // 传出数据
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    }
  });
}

async function getYh(url, data, key) {
  return new Promise((resolve, reject) => {
    // 从缓存中获取
    var record = until.cacheSync("get", key);
    // 获取成功传出值
    if (key && record && record!='') {
      resolve(record);
    } else {// 获取失败发起请求
      // 判断传参方式只有get判断
      if (typeof data === "string" || typeof data === "number") {
        url = url + "/" + data;
        data = {};
      }
      // 请求头
      var header = {
        Authorization: 'Bearer ' +  until.cacheSync("get", 'pigx-access_token').content
        // Authorization: 'Bearer cff21419-8ec5-45c4-bbdd-1085959a7221'
      };
      // 配置参数
      var obj = {
        headers: header,
        method: "get",
        url: base.baseUrl + url,
        params: data,
      };
      // 发起请求
      axios(obj)
          .then((res) => {
            // 传入key说明需要存入缓存
            if (key) {
              until.cacheSync("set", key, res.data);
            }
            // 传出数据
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
    }
  });
}

async function getdownLode(url, data) {
  return new Promise((resolve, reject) => {
    // 请求头
    if (typeof data === "string" || typeof data === "number") {
      url = url + "/" + data;
      data = {};
    }
    var header = {};
    var obj = {
      headers: header,
      method: "get",
      url: base.baseUrl + url,
      params: data,
      responseType: "arraybuffer",
    };

    axios(obj)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
async function post(url, data) {
  return new Promise((resolve, reject) => {
    // 请求头

    var header = {};
    var obj = {
      headers: header,
      method: "post",
      url: base.baseUrl + url,
      data: data,
    };

    axios(obj)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

async function postPassword(url, data) {
  return new Promise((resolve, reject) => {
    // 请求头

    var header = {
      Authorization: 'Bearer ' +  until.cacheSync("get", 'pigx-access_token').content
    };
    var obj = {
      headers: header,
      method: "post",
      url: base.baseUrl + url,
      data: data,
    };

    axios(obj)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
  });
}
// 获取导航菜单的数据
export const getNavigation = (data) => get(base.getNavigationApi, data, 'navigation');
// 获取轮播图列表
export const getBannerList = (data) => get(base.getBannerListApi, data, 'bannerlist');

export const getFooterList = (data) => get(base.getFooterListApi, data, 'footerList');

// 工作组活动分页接口
export const getWorkingList = (data) => get(base.getWorkingListApi, data);
// 工作组活动详情接口
export const getWorkDetail = (data) => get(base.getWorkDetailApi, data);
// 工作组活动详情富文本接口
export const getWorkHtml = (data) => get(base.getWorkHtmlApi, data);
// 基准榜单列表接口
export const bigBillboardList = (data) => get(base.bigBillboardListApi, data);
// 基准榜单列表接口
export const billboardList = (data) => get(base.billboardListApi, data);
// cpuBench列表接口
export const cpuBenchList = (data) => get(base.cpuBenchApi, data);
// 获取榜单id接口
export const cpuBenchBillList = (data) => get(base.cpuBenchBillList, data);
// 下载榜单数据
export const cpuBenchDownLoad = (data) => get(base.cpuBenchDownload, data);
// 性能基准列表接口
export const benchmarkList = (data) => get(base.benchmarkListApi, data);
// 性能基准详情接口
export const benchmarkDetail = (data) =>
  get(base.benchmarkDetailApi, data);
// 工具下载列表接口
export const getToolList = (data) => get(base.getToolListApi, data);
// 工具下载详情接口
export const getToolDetail = (data) => get(base.getToolDetailApi, data);
// 工具下载详情接口 不分页
export const getcppbtoolPage = (data) =>
  get(base.getcppbtoolPageApi, data);
// 工具下载详情接口 不分页
export const getcppbtoolresult = (data) =>
  get(base.getcppbtoolresultApi, data);
// 获取角色列表接口
export const getcppbgetRoles = (data) =>
  get(base.getcppbgetRolesApi, data);
// 下载文件
export const getdownlode = (data) =>
  getdownLode(base.getdownlodeApi, data);
// 打榜报名
export const getSiugnupList = (data) => get(base.getSiugnupListApi, data);
// 获取全部性能基准列表接口
export const benchAllList = (data) => get(base.benchAllListApi, data);
// 获取标准明细接口
export const getCriterionLiCst = (data) =>
  get(base.getCriterionLiCstApi, data);
// 获取标准明细接口
export const getCriterionDetail = (data) =>
  get(base.getCriterionDetailApi, data);
// 获取标准明细列表
export const getstandlist = (data) => get(base.getstandlistApi, data);

export const getcppbtoolList = (data) =>
  get(base.getcppbtoolListApi, data);
export const postUserfromData = (data) =>
  post(base.postUserfromDataApi, data);

// 获取标准明细详情列表
export const postdetailData = (data) => get(base.postdetailDataApi, data);
// 获取标准体系
export const systemData = (data) => get(base.systemDataApi, data, 'system');

// 用户进行反馈
export const feedback = (data) => post(base.feedbackApi, data);
// 判断用户是否可以进行下载
export const power = (data) => get(base.powerApi, data);
// 判断用户名是否存在
export const checkName = (data) => get(base.checkNameApi, data);
// 获取用户成员列表
export const getMember = (data) => get(base.getMemberApi, data);

// 重置密码
export const changePassword = (data) => postPassword(base.changePasswordApi, data);

// 获取用户信息
export const getUserInfo = (data) => get(base.getUserInfoApi, data);

// 获取用户信息
export const getUserRole = (username, data) => get(base.getUserRoleApi + username, data);

// 获取用户信息
export const getUserYh = (data) => getYh(base.getUserApi, data);

// xiugai用户信息
export const getUpdateById = (data) => post(base.getUpdateByIdApi, data);

// xiugai用户信息
export const getSave = (data) => postPassword(base.getSaveApi, data);
