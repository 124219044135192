"use strict";

import Vue from 'vue';
import axios from "axios";

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
// data 数据
let config = {
  // 请求地址 ：测试环境或者开发环境
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  // 请求间隔
  // timeout: 60 * 1000, // Timeout
  // 跨域请求
  // withCredentials: true, // Check cross-site Access-Control
};
// 调用axios 使用自定义配置新建一个 axios 实例
const _axios = axios.create(config);

// 在 request 拦截器实现
_axios.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

////在 response 拦截器实现
_axios.interceptors.response.use(
  function(response) {
    // Do something with response data
    return response;
  },
  function(error) {
    // Do something with response error
    return Promise.reject(error);
  }
);

Plugin.install = function (Vue) {
    // 1.添加全局方法或属性
  Vue.axios = _axios;
  window.axios = _axios;
  // 直接在一个对象上定义新的属性或修改现有属性，并返回该对象
  Object.defineProperties(Vue.prototype, {
    // 传递给函数的对象。
    axios: {
      get() {
        return _axios;
      }
    },
    $axios: {
      get() {
        return _axios;
      }
    },
  });
};

Vue.use(Plugin)

export default Plugin;
 