import Vue from "vue";
import "./plugins/axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./plugins/element.js";
import "../postcss.config.js";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import * as base from "./config/base.js";
import * as api from "./config/api.js";
import * as until from "./config/until.js";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import VueTouch from "vue-touch";

Vue.use(VueTouch, { name: "v-touch" });


import QuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(QuillEditor)


Vue.prototype.$base = base;
Vue.prototype.$api = api;
Vue.prototype.until = until;

Vue.config.productionTip = false;
Vue.use(ElementUI);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
