// 所有的接口地址以及请求头
// export const baseUrl = "http://192.168.1.158";
export const baseUrl = ''
// 获取导航菜单的数据
export const getNavigationApi = "/cms/topic/getAllTopic";
// 获取轮播图列表
export const getBannerListApi = `/cms/carousel/getCarousels`;
export const getFooterListApi = `/cms/partnerLogo/getPartnerLogos`;
// 工作组活动分页接口
export const getWorkingListApi = "/cms/news/page";
// 工作组活动详情接口
export const getWorkDetailApi = "/cms/news";
// 获取富文本接口
export const getWorkHtmlApi = "/cms/cmshtml";
// 基准大榜单列表接口
export const bigBillboardListApi = "/cms/resbillboard/page";
// 基准榜单列表接口
export const billboardListApi = "/cms/result/page";
// 获取榜单id接口
export const cpuBenchBillList = "/cms/cpu_bench";
// cpuBench列表接口
export const cpuBenchApi = "/cms/cpu_bench/release";
// 榜单信息下载接口
export const cpuBenchDownload = "/cms/cpu_bench"
// 性能基准列表接口
export const benchmarkListApi = "/cms/apprstandard/page";
// 获取全部性能基准列表接口
export const benchAllListApi = "/cms/apprstandard/list";
// 性能基准列表接口
export const benchmarkDetailApi = "/cms/apprstandard";
// 工具下载列表接口
export const getToolListApi = "/cms/cppbtool/page";
// 工具下载详情接口
export const getToolDetailApi = "/cms/cppbtool";
// 工具分页接口
export const getcppbtoolPageApi = "/cms/cppbtool/page";
// 获取用户角色、
export const getcppbgetRolesApi = `/admin/member/getRoles`;
//查询用户信息
export const getUserInfoApi = `/admin/member/getUserInfo`;
//查询用户信息
export const getUserRoleApi = `/admin/user/role/`;
//查询用户信息
export const getUserApi = `/admin/tool/getUser`;
// 下载
export const getdownlodeApi = `/admin/sys-file`;
// 打榜报名
export const getSiugnupListApi = "/cms/apply/getApplys";
// 获取标准明细接口
export const getCriterionLiCstApi = "/cms/standardresource/page";
// 获取标准明细接口
export const getCriterionDetailApi = "/cms/standardresource";
// 获取榜单列表
export const getcppbtoolresultApi = `/cms/result`;
// 获取标准明细列表
export const getstandlistApi = `/cms/standardresource/list`;

export const postUserfromDataApi = `/admin/member`;
export const getcppbtoolListApi = "/cms/cppbtool/list";
// 检查用户名
export const checkNameApi = `/admin/member/rechecking`;
// 重置密码
export const changePasswordApi = `/admin/user/edit`;
// 获取标准明细详情列表
export const postdetailDataApi = `/cms/apprstandard/relevance`;
// 获取标准体系
export const systemDataApi = `/cms/standardsys/page`;
// 用户进行反馈
export const feedbackApi = `/cms/userfeedback`;
// 判断用户是否可以进行下载
export const powerApi = `/admin/sysrolefile/checkDownload`;
// 获取用户成员列表
export const getMemberApi = `/cms/standardsys/page`;
// 获取用户成员列表
export const getUpdateByIdApi = `/admin/member/updateById`;

// 添加工具申请信息
export const getSaveApi = `/admin/tool/save`;
