// 同步操作本地缓存4
function cacheSync(type, key, content) {
  if (!key) {
    key = "";
  }
  if (!content) {
    content = "";
  }
  if (type == "set") {
    // 将 data 存储在本地缓存中指定的 key 中
    return sessionStorage.setItem(key, JSON.stringify(content));
  } else if (type == "get") {
    // 从本地缓存中同步获取指定 key 对应的内容。
    var data = sessionStorage.getItem(key);
    if (data == "") {
      return data;
    } else {
      return JSON.parse(data);
    }
  }else if(type == "del"){
    return sessionStorage.removeItem(key);
  }
}
let timeout; //防抖，
// 防抖9
async function debounce(time) {
  return new Promise((resolve) => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      resolve();
    }, time);
  });
}
// 进行路由跳转
function jump(path, type, data) {
  // if (this.$route.path != path) {
    if (!data) {
      data = {};
    }
    if (type == "push") {
      //  跳转到新的页面
      this.$router.push({
        path: path,
        query: data,
      });
    } else if (type == "params") {
      //  关闭当前页面
      this.$router.replace({
        name: path,
        params: data,
      });
    } else if (type == "replace") {
      //  关闭当前页面
      this.$router.replace({
        path: path,
        query: data,
      });
    } else if (type == "go") {
      //  页面进行回退
      this.$router.go(path);
    } else if (type == "href") {
      //   跳转到外部链接
      window.location.href = this.$base.baseUrl + path;
    }
  }
// }

// 节流10
// var canRun = true;
// async function throttle() {
// 	return new Promise((resolve) => {
// 		if (canRun == true) {
// 			clearTimeout(timerun)
// 			canRun = false
// 			var timerun = setTimeout(() => {
// 				canRun = true;
// 			}, 500);
// 			resolve()
// 		}
// 	})
// }

// 设置当前滚动条高度
function setscrollTop(num) {
  document.documentElement.scrollTop = num;
}
module.exports = {
  cacheSync, //对本地缓存的操作
  debounce, //防抖9
  jump,
  // throttle,//节流
  setscrollTop, //设置当前滚动条高度
};
